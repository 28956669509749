import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import LanguageToggle from './LanguageToggle';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useLanguage } from '../contexts/LanguageContext';
import { getTranslation } from '../utils/translations';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HeaderWrapper = styled.header`
  text-align: center;
  padding: 20px 0;
  margin-bottom: 20px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  gap: 5px; /* Reduce gap to make icons closer */
  position: absolute; /* Position absolutely */
  left: 20px; /* Adjust left position */
`;

const TitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 75px;  // Increased from 72px to 80px
  color: ${props => props.$isDarkMode ? 'var(--bg-light)' : 'var(--bg-dark)'};
  font-family: ${props => props.lang === 'ar' ? "'Droid Arabic Kufi', Arial, sans-serif" : "inherit"};

  margin-block: 20px;
  font-weight: 700;
  ${props => props.lang === 'ar' && `
    font-size: 70px !important;  // Increased from 72px to 80px
    letter-spacing: normal;
  `}

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 20px;
  margin-top: 20px;
  gap: 30px;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-size: 18px;
  }
  
  @media (max-width: 480px) {
    gap: 10px;
    font-size: 16px;
  }
`;

const NavItem = styled(Link)`
  color: ${props => props.$isDarkMode ? '#1c1' : '#008000'};
  font-family: ${props => props.lang === 'ar' ? "'Droid Arabic Kufi', Arial, sans-serif" : "inherit"};
  text-decoration: none;
  font-weight: 700;
  transition: color 0.3s ease, transform 0.3s ease;
  
  &:hover {
    color: ${props => props.$isDarkMode ? '#00ff00' : '#00cc00'};
    transform: translateY(-2px);
  }

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: 20px;
  }

  margin: ${props => props.lang === 'ar' ? '0 5px' : '0 10px'};
  font-size: ${props => props.lang === 'ar' ? '18px' : '20px'};

  @media (max-width: 768px) {
    font-size: ${props => props.lang === 'ar' ? '16px' : '18px'};
  }

  @media (max-width: 480px) {
    font-size: ${props => props.lang === 'ar' ? '14px' : '16px'};
  }
`;

// const AuthNavItem = styled(NavItem)`
//   cursor: pointer;
// `;

const Header = () => {
  const { isDarkMode } = useTheme();
  const { language } = useLanguage();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  console.log("Current user in Header:", currentUser);
  console.log("Is user admin?", currentUser?.isAdmin);
  // console.log("User object:", JSON.stringify(currentUser, null, 2));

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      // Add a small delay before navigation
      await new Promise(resolve => setTimeout(resolve, 100));
      navigate('/', { replace: true }); // Use replace to prevent back navigation
    } catch (error) {
      console.error('Error signing out:', error);
      // Optionally show an error toast
      toast.error(getTranslation('header.logoutError', language));
    }
  }, [logout, navigate, language]);

  // Update AuthNavItem to be a button instead of a Link
  const LogoutButton = styled.button`
    background: none;
    border: none;
    color: ${props => props.$isDarkMode ? '#1c1' : '#008000'};
    font-family: ${props => props.lang === 'ar' ? "'Droid Arabic Kufi', Arial, sans-serif" : "inherit"};
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    padding: 0;
    font-size: inherit;
    
    &:hover {
      color: ${props => props.$isDarkMode ? '#00ff00' : '#00cc00'};
      transform: translateY(-2px);
    }
  `;

  const navItems = useMemo(() => [
    { to: "/", label: "header.main" },
    // { to: "/services", label: "header.services" },
    { to: "/projects", label: "header.projects" },
    { to: "/blog", label: "header.blog" },
    { to: "/newsletter", label: "header.newsletter" },
    { to: "/donate", label: "header.donate" },
    // ...(currentUser?.isAdmin ? [
    //   { to: "/admin/blog", label: "header.adminBlog" },
    // ] : []),
  ], []);

  return (
    <HeaderWrapper>
      <HeaderContent>
        <ToggleContainer>
          <ThemeToggle />
          <LanguageToggle />
        </ToggleContainer>
        <TitleContainer>
          <Title $isDarkMode={isDarkMode} lang={language}>
            {getTranslation('header.title', language)}
          </Title>
        </TitleContainer>
      </HeaderContent>
      <Nav aria-label="Main Navigation">
        {navItems.map((item) => (
          <NavItem 
            key={item.to} 
            to={item.to} 
            $isDarkMode={isDarkMode} 
            lang={language}
          >
            {getTranslation(item.label, language)}
          </NavItem>
        ))}
        {/* {currentUser ? (
          <LogoutButton
            onClick={handleLogout}
            $isDarkMode={isDarkMode}
            lang={language}
            type="button"
          >
            {getTranslation('header.logout', language)}
          </LogoutButton>
        ) : (
          <NavItem 
            to="/login" 
            $isDarkMode={isDarkMode} 
            lang={language}
          >
            {getTranslation('header.login', language)}
          </NavItem>
        )} */}
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
